export const prodEnvironment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAUr3uq101J58QcjsbeVQmS_-htKrn03-w',
    authDomain: 'alexander-qr.firebaseapp.com',
    databaseURL: 'https://alexander-qr.firebaseio.com',
    projectId: 'alexander-qr',
    storageBucket: 'alexander-qr.appspot.com',
    messagingSenderId: '449085635889',
    appId: '1:449085635889:web:7b6474b92a923e1cd70410',
    measurementId: 'G-VRFBEGB2XM',
  },
}

export default prodEnvironment
