import React, { useEffect, useRef, useState } from 'react'
import { Code } from './QRCode.types'

import QRCode from 'qrcode.react'
import { useForm } from 'react-hook-form'
import { Button, Input, Option } from '../shared/components'
import { useFirestore } from 'app/firebase'
import { useUI } from 'app/shared/hooks'
import { Select } from 'app/shared/components/Field/Select/Select'

export const QRCodeForm = () => {
  const form = useForm()
  const { createId, create } = useFirestore<Code>('codes')
  const { isMobile } = useUI()

  const id = useRef(createId())
  const [qrCode, setQrCode] = useState<Code & { id: string }>()
  const [svgURI, setSvgURI] = useState<string>()
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    const svg = document.getElementById('svg') as HTMLCanvasElement
    if (!svg) return
    setSvgURI(getCanvasSrc(svg))
  })

  const getCanvasSrc = (el: HTMLElement) =>
    'data:image/svg+xml;base64,' + btoa(el.outerHTML)

  const getPngSrc = (el: HTMLCanvasElement) => el.toDataURL('image/png')

  const onSubmit = async (formData: any) => {
    const { title, url, ...canvasSettings } = formData

    console.log(formData)

    setQrCode({
      id: id.current,
      title: title,
      url: url,
      impressions: 0,
      canvasSettings: canvasSettings,
    })
  }

  const validURL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

  const saveCode = async () => {
    if (!qrCode || !id.current) return
    await create(id.current, qrCode)
    setSaved(true)
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className='mx-auto '>
      <h1 className='p-5 pl-0 text-xl font-semibold text-gray-900'>
        Generate QR Code
      </h1>

      <div className={`${isMobile ? '' : 'flex'}`}>
        <div className={isMobile ? 'p-2' : 'w-1/2'}>
          <Input form={form} required label='Title' name='title' autoFocus />

          <Input
            form={form}
            required
            label='Url'
            name='url'
            pattern={{
              value: validURL,
              message: 'Please Enter Valid URL',
            }}
          />

          <Input
            form={form}
            label='Background Color'
            defaultValue='#eeeeee'
            name='bgColor'
          />

          <Input
            form={form}
            label='Foreground Color'
            defaultValue='#000000'
            name='fgColor'
          />

          <Select
            name='level'
            label='Error Correction'
            form={form}
            defaultValue='L'>
            <Option value='L' label='Low Error Correction' />
            <Option value='Q' label='Low-Medium Error Correction' />
            <Option value='M' label='Medium Error Correction' />
            <Option value='H' label='High Error Correction' />
          </Select>

          {/* <Input
            form={form}
            label='Error Correction(L, M, Q, H)'
            defaultValue='L'
            name='level'
            maxLength={1}
            minLength={1}
            pattern={/[L|M|Q|H]/}
          /> */}

          <Input form={form} label='Size' name='size' defaultValue='200' />

          <Button variant='raised' type='submit' className='m-5'>
            {!qrCode ? 'Generate' : 'Update'} QR Code
          </Button>
        </div>

        <div className={isMobile ? 'm-2' : 'm-10'}>
          <div className='mb-5'>
            {svgURI && qrCode && (
              <>
                {saved && (
                  <a
                    href={svgURI}
                    download={`${qrCode.title}`}
                    className='px-4 py-2 font-bold text-white bg-blue-500 rounded focus:shadow-outline'>
                    Download
                  </a>
                )}

                <a
                  onClick={saveCode}
                  className='px-4 py-2 ml-2 font-bold text-white bg-blue-500 rounded cursor-pointer focus:shadow-outline'>
                  Save
                </a>
              </>
            )}
          </div>

          {qrCode && id && (
            <>
              <QRCode
                {...qrCode.canvasSettings}
                value={`https://qrcadia.com/r/${qrCode.id}`}
                size={500}
                renderAs='svg'
                id='svg'
              />
            </>
          )}
        </div>
      </div>
    </form>
  )
}
