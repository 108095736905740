import { useFirestore, useRouter } from 'app/shared/hooks'
import { tap, first } from 'rxjs/operators'
import React, { useState } from 'react'
import { Code } from './QRCode.types'
import { useObservable } from 'rxjs-hooks'

export const Redirect = () => {
  const { routeParams } = useRouter<{ id: string }>()
  const { getSingle$, update } = useFirestore<Code>('codes')

  useObservable(() =>
    getSingle$(routeParams.id).pipe(
      tap(async ({ impressions, url }) => {
        if (!isNaN(impressions)){
          await update(routeParams.id, { impressions: impressions + 1 })
        }
        document.location.replace(getUrl(url))
      }),
      (e:any ) => {console.log(e); return e},
      first(),
    ),
  )

  const getUrl = (url: string) => {
    return url;
  }

  return (
    <div className='flex justify-center'>
      <img src={require('../../assets/loading-spinner.svg')} />
    </div>
  )
}
