import React from 'react'

export const AwaitingApproval = () => (
  <div className='justify-center text-center'>
    <h1 className='text-6xl text-center text-gray-900'>Welcome To QRcadia!</h1>
    <small className='m-2 text-lg'>
      After an administrator has approved you registration, you will be able to
      use Qrcadia
    </small>
  </div>
)
